@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";


.statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;

    .status {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        margin-left: 0.5rem;
        @include elevation(2);
    }
}


.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1rem;
        font-weight: bold;
        color: black;
    }
}

.fill {
    flex: 1;
}
