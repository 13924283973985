@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.container {
    width: 100%;
    height: 100%;
    color: color("primaryLight");
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
