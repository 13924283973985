@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.strong {
    font-weight: bold;
}

.noItem {
    width: 100%;
    text-align: center;
}