@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.summernote{
    display: block;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    border: 1px solid transparent;
   
    &:focus {
        border: 1px solid color('primary');
        font-size: 16px;
    }    

    .disabled{
        background-color: color('whisper');
        opacity: 1;
    }
}

