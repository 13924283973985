@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    .title {
        font-size: 0.9rem;
        color: black;
    }
}

.projectContainer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .project {
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.eventContainer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .event {
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.originContainer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .origin {
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.fill {
    flex: 1;
}

.dateContainer {
    align-self: flex-end;
    font-size: 0.7rem;
    font-weight: bold;

    .date {
        margin-left: 0.5rem;
    }
}

.messageContainer {
    position: absolute;
    top: 1rem;
    right: 1rem;
} 