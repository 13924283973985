@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.input {
    display: block;
    border: 0;
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    width: 100%;
    outline: 0;
    font-family: 'Roboto', sans-serif;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 25px;
    opacity: 1;

    &:focus {
        border: 1px solid color('primaryLight');
    }
    &::placeholder{
        font-family: 'Roboto', sans-serif;
    }
}

.inputWithIcon {
    display: block;
    border: 0;
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    width: 50%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;

    &:focus {
        border: 1px solid color('primaryLight');
    }
}

.default {
    background-color: color('white');
    border: 1px solid color('mercury');
}

.login {
    background-color: color('white');
}

.disabled{
    background-color: color('whisper');
    opacity: 1;
}

.maxLength{
    font-size: 12px;
    color: color('primaryDark');
    display: block;
    margin-bottom: 4px;
}

.iconInput {
    color: #aaa;
    background-color: color('whisper');
    color: color('black');
    border-radius: 0 2px 2px 0;
    padding: 0.8rem 1rem;

}

.formLine {
    display: flex;
    flex-direction: row;
}
  

.textArea{
    width: 100%;
    flex: 1 1 auto;
    padding: 0.8rem 1rem;
    border: 1px solid color('mercury');
    border-radius: 25px;
    outline: none;
    font-size: 16px;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-family: 'Roboto', sans-serif;

    &:disabled{
        background-color: color('whisper');
        opacity: 1;
    }
}