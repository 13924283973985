@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$itemVerticalPadding: 1.2rem;
$itemHorizontalPadding: 1.5rem;

.container {
    width: 100%;
}

.list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .listItem {
        padding: 0.5rem 1rem;
        cursor: pointer;
        word-break: initial;
        font-size: 0.875rem;
        margin-right: 0.5rem;
        border-top: 1.5px solid transparent;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &.listItemActive {
            @include fontWeight("bold");
            border-top-color: color("primaryLight");
            background-color: color("white");
        }

        .floatingBadge {
            align-items: center;
            display: flex;
            justify-content: center;
            color: color("white");
            border-radius: 50%;
            background-color: color("danger");
            position: absolute;
            height: 15px;
            top: 0;
            right: 0;
            transform: translate(75%, -25%);
            font-size: 0.5rem;
            font-weight: bold;
            padding: 0.3rem;
        }
    }
}

.content {
    padding: 1rem;
    background-color: color("white");
    box-shadow: 0px 2px 10px #0000001a;
}

.warningContainer {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0;
    right: 0;

    .icon {
        width: 100%;
        height: 100%;
    }
}
