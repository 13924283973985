@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.table {
    background-color: color("white");
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
}

.columnHeader {
    padding: 0.5rem 1rem;
    color: color("alto");
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fontWeight("normal");

}

.bodyColumn {
    padding: 1rem;
    word-break: keep-all;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid color("gallery");
    font-size: 1rem;
    border-radius: 2px;
}

.rowHover:hover {
    cursor: pointer;
    transition: 0.2s;

    .bodyColumn {
        background-color: rgba(color("alabaster"), 0.5);
    }
}

.selected {
    background-color: color("alabaster");
}

.striped {
    background-color: color("gallery");
}

.noItemsText {
    padding: 0.5rem;
    text-align: center;
}

.sm {
    @include respond-below(sm) {
        display: none !important;
    }
}

.md {
    @include respond-below(md) {
        display: none !important;
    }
}

.lg {
    @include respond-below(lg) {
        display: none !important;
    }
}

.xl {
    @include respond-below(xl) {
        display: none !important;
    }
}

.mobile {
    font-size: 0.875rem !important;
}

.headerInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    word-break: keep-all;

    .headerInfoImage {
        margin-left: 0.5rem;
    }
}

.clicakble {
    cursor: pointer;
}
