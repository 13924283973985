@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.container {
    .rowSpace {
        margin-bottom: 1rem;
    }

    .colSpace {
        margin-bottom: .5rem;
    }

    .label {
        text-align: left;
        font: normal normal bold 14px/17px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 0.8rem;
    }
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.permissionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tabContainer {
    margin-top: .5rem;
    margin-bottom: 1rem;
}