@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.columnPlate {
    @include fontWeight('bold');
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

.tabHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.counter {
    border-radius: 50%;
    border: 1px solid color('primaryDark');
    background-color: color('white');
    color: color('primaryDark');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight('bold');
}

.counterNumber{
    padding-top: 2px;
}

.rowFlex{
    display: flex;
}

.center {
    text-align: center;
}