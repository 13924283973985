@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

$bottomContainerHeight: 80%;
$middleContainerPadding: 5rem;

.container {
    width: 100%;
    height: 100%;
    color: color("primaryLight");
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .imageContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;

        .image {
            width: 150px;
            height: auto;
        }
    }

    .descriptionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .description {
            color: black;
        }
    }

    .returnToHome {
        margin-top: 4rem;
    }
}
