@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.inputGroup {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 25px;    
    background-color: color('white');
}

.input {
    display: block;
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    outline: 0;
    border-radius: 25px 0 0 25px;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    
    border-color: hsl(0, 0%, 80%);
    border: 1px solid color('mercury');

    &:focus {
        border: 1px solid color('primaryLight');
    }
    &:focus ~ .inputGroupAppend > .inputGroupText {
        border: 1px solid color('primaryLight');
    }
}

.disabled{
    background-color: color('whisper');
    opacity: 1;
}

.maxLength{
    font-size: 12px;
    color: color('primaryDark');
    display: block;
    margin-bottom: 4px;
}

.inputGroupAppend {
    margin-left: -1px;
    display: flex;
    align-self: stretch;
}

.inputGroupText {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: color('black');
    text-align: center;
    white-space: nowrap;
    background-color: color('athensGray');
    @include fontWeight('bold');
    border-radius: 0 25px 25px 0;
    border-color: hsl(0, 0%, 80%);
    border: 1px solid color('mercury');
}

.nameText {
    @include fontWeight('bold');
    font-size: 16px;
    margin: 0 10px;
}

.inputMoney {
    text-align: right;
}

.cursorPointer{
    cursor: pointer;
}

   
.imageIconClose {
    height: 1rem;
    cursor: pointer;
}


.inputWithButton {
    position: relative;

    .clearButton {
        position: absolute;
        display: flex;
        justify-content: center;
        cursor: pointer;
        right: 4rem;
        fill: hsl(0, 0%, 80%);
    } 
 }