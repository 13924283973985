@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1rem;
        font-weight: bold;
        color: black;
    }
}

.fill {
    flex: 1;
}

.dateContainer {
    font-size: 0.7rem;

    .date {
        margin-left: 0.5rem;
        font-weight: bold;
    }
}

.sizeContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;

    .size {
        margin-left: 0.5rem;
        font-weight: bold;
    }
}

.alertContainer {
    position: absolute;
    top: 1rem;
    right: 1rem;

    .alert {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.marginRight {
    margin-right: 2rem;
}