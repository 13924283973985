@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1rem;
        font-weight: bold;
        color: black;
    }
}

.fill {
    flex: 1;
}

.dateContainer {
    align-self: flex-end;
    font-size: 0.7rem;
    font-weight: bold;

    .date {
        margin-left: 0.5rem;
    }
}

.alertContainer {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;

    .status {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        margin-left: 0.5rem;
        @include elevation(2);
    }
}

.availableSpaceContainer {
    font-size: 0.7rem;

    .availableSpace {
        margin-left: 0.5rem;
        font-weight: bold;
    }
}

.floatingBadge {
    align-items: center;
    display: flex;
    justify-content: center;
    color: color("white");
    border-radius: 50%;
    background-color: color("danger");
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0;
    right: 0;
    font-size: 0.6rem;
    line-height: 0;
    text-align: center;
}