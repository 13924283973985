@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.serverCell {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.cardContainer {
    @include elevation(1);
    border-radius: 3px;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: bold;
            color: black;
        }

        .requirementContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
        
            .conditions {
                margin-left: 0.5rem;
            }
        }
    }

    .fill {
        flex: 1;
    }

    .date {
        align-self: flex-end;
    }
}



.conditionOk {
    width: 1rem;
    height: 1rem;
    background: green;
    border-radius: 50%;
    @include elevation(2);
}

.conditionImageContainer {
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .conditionImage {
        width: 100%;
        height: 100%;
    }
}

.serverRepetitionContainer {
    position: relative;
    padding: 0.5rem 0 0 0;

    .serverRepetition {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(75%, 0);
        background: color(primaryLight);
        border-radius: 50%;
        font-size: 0.65rem;
        width: 1rem;
        height: 1rem;
        color: color(primaryDark10);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        @include elevation(2);
    }
}
