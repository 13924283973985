@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";

$defaultPadding: 2rem;
$bottomDefaultPadding: 2.6rem;
$floatingBadgeSize: 20px;

.container {
    height: 100%;
    background: color("primaryLight");
    background: linear-gradient(180deg, color("primaryLight") 0%, color("primaryDark") 100%);
    color: color("white");
}

.innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logoContainer {
    padding: $defaultPadding;
    margin-bottom: 1rem;
    text-align: center;

    img {
        width: 100%;
        max-width: 10rem;
        height: auto;
    }
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    @include scrollbars();
}

.item {
    color: color("white");
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    @include fontWeight("light");
    font-size: 1.25rem;
    transition: 0.1s;
    padding: 1.7rem 2rem;

    &.itemActive,
    &:hover {
        border-top-left-radius: 5rem;
        border-bottom-left-radius: 5rem;
        background-color: rgba(color("white"), 0.3);
    }

    &:hover {
        @include fontWeight("light");
    }

    &.itemActive {
        @include fontWeight("bold");
    }

    &:before {
        margin-top: -1px;
    }

    .itemText {
        position: relative;

        .floatingBadge {
            align-items: center;
            display: flex;
            justify-content: center;
            color: color("white");
            border-radius: 9999px;
            background-color: color("danger");
            position: absolute;
            height: $floatingBadgeSize;
            top: 0;
            right: 0;
            transform: translate(75%, -25%);
            font-size: 0.6rem;
            padding: 0.5rem;
        }
    }
}

.bottom {
    padding: $defaultPadding;
    padding-top: 0;
    position: relative;
}

.profileItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid color("white");
    margin-bottom: 1rem;
    padding: 0.8rem 0 1rem 0;
    position: relative;
    cursor: pointer;

    .profileAvatar {
        margin-right: 0.6rem;
    }

    .profileInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .profileName {
        font-size: 0.875rem;
        @include fontWeight("bold");
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            flex: 1;
        }

        .profileChevron {
            fill: color("white");
            width: 0.5rem;
        }
    }

    .profileRole {
        font-size: 0.75rem;
        @include fontWeight("normal");
        line-height: 1;
    }
}

.copyright {
    p {
        margin: 0;
        padding: 0;
        font-size: 0.75rem;
        @include fontWeight("normal");
    }
}

.popoverContainer {
    width: 13rem;
}
