@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.title {
    font-weight: bold;
}

.noProject {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .dateContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .dateContainerTooltip {
            display: flex;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
        }
    }

    .title {
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.marginBottom {
    margin-bottom: 0.5rem;
}
