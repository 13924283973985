
body {
    font-family: 'Roboto', sans-serif;
    background-color: color('athensGray');
    height: 100%;
}

html {
    box-sizing: border-box;
    height: 100%;
}

#root {
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.container-wrapper {
    @include containerWrapper;
}
