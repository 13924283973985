.form {
    margin-top: 1.5rem;

    .label {
        font-weight: bold;
        font-size: 0.9rem;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    .roles {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }    
    
    .avatarChooser {
        text-align: center;
        padding-left: 1rem;

        .avatarImage {
            margin-bottom: 1rem;
            width: 6rem;
        }

        .avatarFilesList {
            justify-content: center;
        }
    }
}
