@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

// :root {
//     --max-width: 250px;
//     --max-height: 250px;

//     @include respond-below(lg) {
//         --max-width: 200px;
//         --max-height: 200px; 
//     }

//     @include respond-below(md) {
//         --max-width: 150px;
//         --max-height: 150px; 
//     }
// }

.button {
    border: 0;
    color: color("white");
    border-radius: 25px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    margin-left: 1rem;
    &.disabled {
        opacity: 0.2;
        cursor: not-allowed;

        &.primary {
            box-shadow: -7px 8px 23px 0px color("primary");
        }
    }

    &.sizeNormal {
        padding: 0.7rem 1.8rem;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.5rem 1rem;
        font-size: 14px;
    }

    &.sizeExtraSmall {
        padding: 0.5rem 1rem;
        font-size: 10px;
    }
}

.buttonIcon {
    border: 0;
    color: color("white");
    border-radius: 50%;
    font-size: 13px;
    cursor: pointer;
    outline: 0;
    display: inline-block;

    &.sizeNormal {
        padding: 0.1rem;
        width: 43px;
        height: 43px;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.1rem;
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    &.sizeExtraSmall {
        padding: 0.1rem;
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
}

.primary {
    background-color: color("primaryDark");
    border: 1px solid color("primaryDark");

    &:hover {
        background-color: darken(color("primaryDark"), 5%);
    }
}

.primaryAlt {
    background-color: color("defaultGreen");
    border: 1px solid color("defaultGreen");

    &:hover {
        background-color: darken(color("defaultGreen"), 5%);
    }
}

.danger {
    background-color: color("danger");
    border: 1px solid color("danger");

    &:hover {
        background-color: darken(color("danger"), 5%);
    }
}

.success {
    background-color: color("success");
    border: 1px solid color("success");

    &:hover {
        background-color: darken(color("success"), 5%);
    }
}

.secondary {
    background-color: color("white");
    color: color("primaryDark");
    border: 2px solid color("primaryDark");

    &:hover {
        background-color: darken(color("white"), 5%);
    }
}

.popover {
    background-color: color("white");
    color: color("skyBlue");
    border: 1px solid color("skyBlue");

    &:hover {
        background-color: darken(color("white"), 5%);
    }
}

.secondaryDanger {
    background-color: color("white");
    color: color("danger");

    &:hover {
        background-color: darken(color("white"), 5%);
    }
}

.container {
    // max-width: var(--max-width);
}

.scrollable {
    overflow-x: auto;
    @include scrollbars();
}

.mediasList {
    display: inline-flex;
    padding: 15px;
}

.addMedia {
    padding: 1rem;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    @include respond-below(md) {
        width: 30px;
        height: 30px;
    }
}

.imagePreviewContainer {
    position: relative;
    margin-top: 0.5rem;
    padding: 0 15px 15px 15px;

    .removeItem {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: none;
        cursor: pointer;
    }

    &:hover .removeItem {
        display: block;
    }
}

.imagePreview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: var(--max-height);
    border: 1px solid color("mercury");
}

.imagePreviewMini {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid color("mercury");
    margin-right: 0.5rem;
    cursor: pointer;

    @include respond-below(md) {
        width: 30px;
        height: 30px;
    }
}

.editorContainer {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}




    .img{
        height:100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
