@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.message {
    display: block;
    @include fontWeight('light');
    font-size: 14px;
    margin-bottom: 4px;
    color: color('danger');
}
